import React, { useEffect } from 'react';
import { Routes, Route } from 'react-router-dom';
import { auth0Client } from 'Auth';
import { AppContainer, GlobalStyles } from './App.style';
import { ThemeProvider } from 'styled-components';
import { MuiThemeProvider } from '@material-ui/core/styles';
import './i18n';
import Authenticator from 'components/Auth/Authenticator/Authenticator';
import UserScans from 'components/UserScans/UserScans';
import Register from 'components/Auth/Register/Register';
import ErrorView from './views/ErrorView/ErrorView';
import { SCAN_URL } from './utils/environment-variables';
import BrowserNotSupportedView from './components/shared/BrowserNotSupportedView/BrowserNotSupportedView';
import { ConsentBanner, useConsent } from 'react-hook-consent';
import 'react-hook-consent/dist/styles/style.css';
import { useTranslation } from 'react-i18next';
import { isBrowserSupportedForRecording, isDesktop } from './utils/device-data';

const App = (props) => {
    const { materialTheme, theme } = props;
    const { isBannerVisible } = useConsent();
    const { t } = useTranslation();

    useEffect(() => {
        if (isBannerVisible) {
            document.body.style.overflow = 'hidden';
        } else {
            document.body.style.overflow = '';
        }
        return () => {
            document.body.style.overflow = '';
        };
    }, [isBannerVisible]);

    const isDesktopDevice = isDesktop()
    const isRecordingSupported = isBrowserSupportedForRecording();

    const scanUrl = SCAN_URL || '';

    const onLogout = () => {
        // todo: move to redux-saga
        if (auth0Client) {
            auth0Client.logout({ returnTo: scanUrl });
        }
    };

    return (
        <MuiThemeProvider theme={materialTheme}>
            <ThemeProvider theme={theme}>
                <React.Fragment>
                    <GlobalStyles />
                    <AppContainer>
                        <Routes>
                            {isDesktopDevice && !isRecordingSupported && (
                                <Route
                                    path="*"
                                    element={<BrowserNotSupportedView />}
                                />
                            )}
                            {(isRecordingSupported || !isDesktopDevice) && (
                                <>
                                    <Route
                                        exact
                                        path="/"
                                        element={
                                            <Authenticator>
                                                <UserScans
                                                    onLogout={onLogout}
                                                />
                                            </Authenticator>
                                        }
                                    />
                                    <Route
                                        path="/:companySlug/"
                                        element={
                                            <ErrorView
                                                error={{
                                                    code: 'IncorrectURL',
                                                }}
                                            />
                                        }
                                    />
                                    <Route
                                        path="/register/:targetGroupId"
                                        element={<Register />}
                                    />
                                    <Route
                                        path="/register/:companySlug/:targetGroupId"
                                        element={<Register />}
                                    />
                                    <Route
                                        path="/register/"
                                        element={
                                            <ErrorView
                                                error={{
                                                    code: 'IncorrectURL',
                                                }}
                                            />
                                        }
                                    />
                                    <Route
                                        path="/:companySlug/:assessmentId/"
                                        element={
                                            <ErrorView
                                                error={{
                                                    code: 'IncorrectURL',
                                                }}
                                            />
                                        }
                                    />
                                    <Route
                                        path="/:companySlug/:assessmentId/:invitationId"
                                        element={<Authenticator />}
                                    />
                                </>
                            )}
                        </Routes>
                        <div className={isBannerVisible ? 'cookie-theme' : ''}>
                            <ConsentBanner
                                decline={{
                                    hidden: true,
                                }}
                                approve={{
                                    label: t(
                                        'COOKIE_CONSENT.BUTTON_TEXT_APPROVE_ALL',
                                    ),
                                }}
                                settings={{
                                    modal: {
                                        decline: { hidden: true },
                                        approveAll: {
                                            label: t(
                                                'COOKIE_CONSENT.BUTTON_TEXT_APPROVE_ALL',
                                            ),
                                        },
                                        approve: {
                                            label: t(
                                                'COOKIE_CONSENT.BUTTON_TEXT_APPROVE_SELECTION',
                                            ),
                                        },
                                        title: t(
                                            'COOKIE_CONSENT.SETTINGS_TITLE',
                                        ),
                                        description: t(
                                            'COOKIE_CONSENT.SETTINGS_DESCRIPTION',
                                        ),
                                    },
                                    label: t(
                                        'COOKIE_CONSENT.BUTTON_TEXT_SETTINGS',
                                    ),
                                }}>
                                <div className="cookie-title">
                                    {t('COOKIE_CONSENT.TITLE')}
                                </div>
                                <br />
                                <div>
                                    {t('COOKIE_CONSENT.DESCRIPTION')}
                                    <br></br>
                                    {t('COOKIE_CONSENT.DESCRIPTION_2')}
                                </div>
                                <br></br>
                            </ConsentBanner>
                        </div>
                    </AppContainer>
                </React.Fragment>
            </ThemeProvider>
        </MuiThemeProvider>
    );
};

export default App;
