import { UAParser } from 'ua-parser-js';

const parser = new UAParser();

export function getViewportData() {
    return {
        width: window.screen.width,
        height: window.screen.height,
        pixelRatio: window.devicePixelRatio,
    };
}

export function getWindowData() {
    return {
        size: {
            width: window.innerWidth,
            height: window.innerHeight,
        },
        position: {
            x: window.screenLeft || window.screenX,
            y: window.screenTop || window.screenY,
        },
    };
}

export function getBrowserData() {
    return parser.getBrowser();
}

export function getDeviceData() {
    return {
        engine: parser.getEngine(),
        os: parser.getOS(),
    };
}

export const isBrowserSupportedForRecording = () => {
    const engine = parser.getEngine();

    switch (engine.name) {
        case 'Blink': // Chromium
            return true;
        case 'WebKit': // Safari
            return false;
        case 'Gecko': // Firefox
            return false;
        default:
            return false;
    }
};

export const isDesktop = () => {
    const device = parser.getDevice().withFeatureCheck();
    return device.type === undefined || !['console', 'mobile', 'tablet', 'smarttv', 'wearable', 'embedded'].includes(device.type);
};
