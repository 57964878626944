import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
    Container,
    TwoColumnsLayout,
    VideoColumn,
    ContentColumn,
    Text,
    StepsList,
    Step,
    StepLabel,
    StepExplanation,
    StepInfoIcon,
    CameraComponentContainer,
    SpinnerContainer,
    ContentFooter,
    ButtonContainer,
    Line,
    DescWrapper,
    ConditionsWrapper,
    ConditionsParagraph,
    CameraSetupComponent,
    IconWrapper,
} from './CameraSetup.style';
import { ReactComponent as EnoughLightIcon } from 'assets/icons/Lighting_icon.svg';
import { ReactComponent as FaceDistanceIcon } from 'assets/icons/Face_distance_icon.svg';
import { ReactComponent as FaceCenteredIcon } from 'assets/icons/Face_centered_icon.svg';
import { ReactComponent as BlockFaceIcon } from 'assets/icons/Block_face_icon.svg';

import ShadowButton from 'components/shared/ShadowButton/ShadowButton';
import CameraPreview from 'components/shared/CameraPreview/CameraPreview';
import ProgressSpinner from 'components/shared/ProgressSpinner/ProgressSpinner';
import CameraSetupDialogs from 'components/shared/CameraSetupDialogs/CameraSetupDialogs';

import { InfoSignIcon } from 'evergreen-ui';
import RoundedCheckbox from '../RoundedCheckbox/RoundedCheckbox';
import withMediaQuery from '../../../hoc/MediaQuery/withMediaQuery';

const CameraSetup = (props) => {
    const { t } = useTranslation();
    const { onComplete, videoStream, isTabletAndMobileSize } = props;

    const [showHelpCamera, setShowHelpCamera] = useState(false);
    const [showStepOneDialog, setShowStepOneDialog] = useState(false);
    const [showStepTwoDialog, setShowStepTwoDialog] = useState(false);
    const [showStepThreeDialog, setShowStepThreeDialog] = useState(false);
    const [showStepFourDialog, setShowStepFourDialog] = useState(false);
    const [environmentAccepted, setEnvironmentAccepted] = useState(false);

    const onCameraHelp = () => {
        setShowHelpCamera(true);
    };

    return (
        <CameraSetupComponent>
            <Container>
                <TwoColumnsLayout>
                    {isTabletAndMobileSize && (
                        <Text>
                            <h1>
                                {t('CAMERA_SETUP_VIEW.SET_UP_CAMERA.TITLE')}
                            </h1>
                        </Text>
                    )}
                    <VideoColumn>
                        {videoStream ? (
                            <CameraComponentContainer>
                                <CameraPreview
                                    videoStream={videoStream}
                                    isPreviewPage={true}
                                    cameraHelp={onCameraHelp}
                                    isMobileSize={false}
                                    text={t(
                                        'CAMERA_SETUP_VIEW.SET_UP_CAMERA.HELP_BUTTON',
                                    )}
                                />
                            </CameraComponentContainer>
                        ) : (
                            <SpinnerContainer>
                                <ProgressSpinner />
                            </SpinnerContainer>
                        )}
                    </VideoColumn>
                    <ContentColumn>
                        <Text>
                            {!isTabletAndMobileSize && (
                                <h1>
                                    {t('CAMERA_SETUP_VIEW.SET_UP_CAMERA.TITLE')}
                                </h1>
                            )}
                            <p>
                                {t('CAMERA_SETUP_VIEW.SET_UP_CAMERA.MESSAGE')}
                            </p>
                        </Text>
                        <StepsList>
                            <Step>
                                <div>
                                    <StepLabel>
                                        {t(
                                            'CAMERA_SETUP_VIEW.SET_UP_CAMERA.STEP_1_TITLE',
                                        )}
                                    </StepLabel>
                                    <IconWrapper>
                                        <EnoughLightIcon className="camera-setup-icon" />
                                    </IconWrapper>
                                    <StepExplanation>
                                        {t(
                                            'CAMERA_SETUP_VIEW.SET_UP_CAMERA.STEP_1_EXPLANATION',
                                        )}
                                    </StepExplanation>
                                </div>
                                <StepInfoIcon>
                                    <InfoSignIcon
                                        size={20}
                                        onClick={() =>
                                            setShowStepOneDialog(true)
                                        }
                                    />
                                </StepInfoIcon>
                            </Step>
                            <Step>
                                <div>
                                    <StepLabel>
                                        {t(
                                            'CAMERA_SETUP_VIEW.SET_UP_CAMERA.STEP_2_TITLE',
                                        )}
                                    </StepLabel>
                                    <IconWrapper>
                                        <FaceDistanceIcon className="camera-setup-icon" />
                                    </IconWrapper>
                                    <StepExplanation>
                                        {t(
                                            'CAMERA_SETUP_VIEW.SET_UP_CAMERA.STEP_2_EXPLANATION',
                                        )}
                                    </StepExplanation>
                                </div>
                                <StepInfoIcon>
                                    <InfoSignIcon
                                        size={20}
                                        onClick={() =>
                                            setShowStepTwoDialog(true)
                                        }
                                    />
                                </StepInfoIcon>
                            </Step>
                            <Step>
                                <div>
                                    <StepLabel>
                                        {t(
                                            'CAMERA_SETUP_VIEW.SET_UP_CAMERA.STEP_3_TITLE',
                                        )}
                                    </StepLabel>
                                    <IconWrapper>
                                        <FaceCenteredIcon className="camera-setup-icon" />
                                    </IconWrapper>
                                    <StepExplanation>
                                        {t(
                                            'CAMERA_SETUP_VIEW.SET_UP_CAMERA.STEP_3_EXPLANATION',
                                        )}
                                    </StepExplanation>
                                </div>
                                <StepInfoIcon>
                                    <InfoSignIcon
                                        size={20}
                                        onClick={() =>
                                            setShowStepThreeDialog(true)
                                        }
                                    />
                                </StepInfoIcon>
                            </Step>
                            <Step>
                                <div>
                                    <StepLabel>
                                        {t(
                                            'CAMERA_SETUP_VIEW.SET_UP_CAMERA.STEP_4_TITLE',
                                        )}
                                    </StepLabel>
                                    <IconWrapper>
                                        <BlockFaceIcon className="camera-setup-icon" />
                                    </IconWrapper>
                                    <StepExplanation>
                                        {t(
                                            'CAMERA_SETUP_VIEW.SET_UP_CAMERA.STEP_4_EXPLANATION',
                                        )}
                                    </StepExplanation>
                                </div>
                                <StepInfoIcon>
                                    <InfoSignIcon
                                        size={20}
                                        onClick={() =>
                                            setShowStepFourDialog(true)
                                        }
                                    />
                                </StepInfoIcon>
                            </Step>
                        </StepsList>
                        <ContentFooter>
                            <ConditionsWrapper>
                                <RoundedCheckbox
                                    id="acceptConditions"
                                    onChange={(event) => {
                                        setEnvironmentAccepted(event);
                                    }}
                                />
                                <ConditionsParagraph>
                                    {t(
                                        'CAMERA_SETUP_VIEW.SET_UP_CAMERA.ENV_ACCEPTED',
                                    )}
                                </ConditionsParagraph>
                            </ConditionsWrapper>
                            <ButtonContainer>
                                <ShadowButton
                                    disabled={!environmentAccepted}
                                    onClick={onComplete}>
                                    {t(
                                        'CAMERA_SETUP_VIEW.SET_UP_CAMERA.CONTINUE_BUTTON',
                                    )}
                                </ShadowButton>
                            </ButtonContainer>
                        </ContentFooter>
                    </ContentColumn>
                </TwoColumnsLayout>
                <CameraSetupDialogs
                    showHelpCamera={showHelpCamera}
                    closeHelpCamera={() => setShowHelpCamera(false)}
                    showStepOneDialog={showStepOneDialog}
                    closeStepOneDialog={() => setShowStepOneDialog(false)}
                    showStepTwoDialog={showStepTwoDialog}
                    closeStepTwoDialog={() => setShowStepTwoDialog(false)}
                    showStepThreeDialog={showStepThreeDialog}
                    closeStepThreeDialog={() => setShowStepThreeDialog(false)}
                    showStepFourDialog={showStepFourDialog}
                    closeStepFourDialog={() => setShowStepFourDialog(false)}
                />
            </Container>
            <Line></Line>
            <DescWrapper id={'CAMERA_HELP_DESC'}>
                <div className={'desc-title-setup'}>
                    {t('CAMERA_SETUP_VIEW.SET_UP_CAMERA.HELP_TITLE')}
                </div>
                <div className={'desc-setup'}>
                    {t('CAMERA_SETUP_VIEW.SET_UP_CAMERA.HELP_DESC')}
                </div>
            </DescWrapper>
        </CameraSetupComponent>
    );
};

export default withMediaQuery(CameraSetup);
