import React from 'react';
import { useTranslation } from 'react-i18next';
import i18next from 'i18next';

/* import { AppLayout } from 'styles/shared.style'; */
import {
    Container,
    StepContainer,
    StepLabelContainer,
    StepImage,
} from './Stepper.style';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepConnector from '@material-ui/core/StepConnector';
import StepLabel from '@material-ui/core/StepLabel';
import clsx from 'clsx';
import { TickIcon } from 'evergreen-ui';
import { withTranslation } from 'react-i18next';

import culturalFitImage from 'assets/images/cultural_fit_module.png';
import speakingImage from 'assets/images/speaking_module.png';
import cognitiveImage from 'assets/images/cognitive_abilities_module.png';
import questionnaireImage from 'assets/images/questionnaire_module.png';
import withMediaQuery from '../../../hoc/MediaQuery/withMediaQuery';

const rootStyles = makeStyles({
    root: {
        background: 'none',
    },
    label: {
        fontWeight: 500,
    },
});
const StepperConnectorStyle = withStyles({
    alternativeLabel: {
        top: 22,
    },
    line: {
        height: 0,
        border: 0,
        backgroundColor: '#435970',
        borderRadius: 1,
    },
})(StepConnector);

const MobileSizeStepperConnectorStyle = withStyles({
    alternativeLabel: {
        top: 22,
    },
    line: {
        width: 0,
        border: 0,
        backgroundColor: '#435970',
        borderRadius: 1,
        marginLeft: '4px',
        minHeight: '3em',
    },
})(StepConnector);

const stepperStyles = makeStyles({
    root: {
        fontFamily: 'Roboto',
        background: '#eaeaea',
        color: '#435970',
        zIndex: 1,
        width: 45,
        height: 45,
        fontSize: '18px',
        fontWeight: 'bold',
        display: 'flex',
        borderRadius: '50%',
        justifyContent: 'center',
        alignItems: 'center',
    },

    active: {
        background: '#eaeaea',
        color: '#435970',
    },
    completed: {
        background: '#00a5ad',
        color: '#fff',
    },
});

const stepperMobileStyles = makeStyles({
    root: {
        fontFamily: 'Roboto',
        background: '#eaeaea',
        color: '#435970',
        zIndex: 1,
        width: 32,
        height: 32,
        fontSize: '0.8em',
        fontWeight: 'bold',
        display: 'flex',
        borderRadius: '50%',
        justifyContent: 'center',
        alignItems: 'center',
    },

    active: {
        background: '#eaeaea',
        color: '#435970',
    },
    completed: {
        background: '#00a5ad',
        color: '#fff',
    },
});

const StepCustomLabel = (props) => {
    const { active, completed, icon } = props;
    const classes = stepperStyles();

    return (
        <StepContainer
            className={clsx(classes.root, {
                [classes.active]: active,
                [classes.completed]: completed,
            })}>
            {!completed && <span>{icon}</span>}
            {completed && <TickIcon size={20} />}
        </StepContainer>
    );
};
const StepMobileCustomLabel = (props) => {
    const { active, completed, icon } = props;
    const classes = stepperMobileStyles();

    return (
        <StepContainer
            className={clsx(classes.root, {
                [classes.active]: active,
                [classes.completed]: completed,
            })}>
            {!completed && <span>{icon}</span>}
            {completed && <TickIcon size={16} />}
        </StepContainer>
    );
};

const StepperComponent = (props) => {
    const { isMobileSize, activeStep, steps } = props;
    const classes = rootStyles();
    const { t } = useTranslation();

    const getStepImage = (step) => {
        switch (step.id) {
            case 'culture-fit':
                return culturalFitImage;
            case 'speaking':
                return speakingImage;
            case 'mental-test':
                return cognitiveImage;
            case 'questionnaire':
                return questionnaireImage;
            default:
                return questionnaireImage;
        }
    };

    const getStepTranslation = (step) => {
        switch (step.id) {
            case 'culture-fit':
                return t('ASSESSMENT_PROGRESSION_VIEW.TASK_LABELS.CULTURE_FIT');
            case 'culture-fit-questionnaire':
                return t(
                    'ASSESSMENT_PROGRESSION_VIEW.TASK_LABELS.CULTURE_FIT_QUESTIONNAIRE',
                );
            case 'speaking':
                return t('ASSESSMENT_PROGRESSION_VIEW.TASK_LABELS.SPEAKING');
            case 'mental-test':
                return t('ASSESSMENT_PROGRESSION_VIEW.TASK_LABELS.MENTAL_TEST');
            case 'questionnaire':
                return t('CUSTOM_MODULE.LABEL', {
                    module_label: step.label[i18next.language],
                });
            default:
                return null;
        }
    };
    return (
        <Container>
            {!isMobileSize && (
                <Stepper
                    alternativeLabel
                    activeStep={activeStep}
                    connector={<StepperConnectorStyle />}
                    className={classes.root}>
                    {steps &&
                        steps.map((step) => (
                            <Step key={step.id}>
                                <StepLabel StepIconComponent={StepCustomLabel}>
                                    <StepLabelContainer
                                        className={classes.label}>
                                        <span>{getStepTranslation(step)}</span>
                                        <StepImage
                                            alt="Step"
                                            src={getStepImage(step)}
                                        />
                                    </StepLabelContainer>
                                </StepLabel>
                            </Step>
                        ))}
                </Stepper>
            )}
            {isMobileSize && (
                <Stepper
                    orientation="vertical"
                    activeStep={activeStep}
                    connector={<MobileSizeStepperConnectorStyle />}>
                    {steps &&
                        steps.map((step) => (
                            <Step key={step.id}>
                                <StepLabel
                                    StepIconComponent={StepMobileCustomLabel}>
                                    <StepLabelContainer
                                        className={classes.label}>
                                        <span>{getStepTranslation(step)}</span>
                                        <StepImage
                                            alt="Step"
                                            src={getStepImage(step)}
                                        />
                                    </StepLabelContainer>
                                </StepLabel>
                            </Step>
                        ))}
                </Stepper>
            )}
        </Container>
    );
};

export default withMediaQuery(withTranslation()(StepperComponent));
