export const isScanOnlyWorkPreferences = (scanModules) => {
    return !scanModules.find((module) => module.id === 'allow-camera-access');
};

export const hasCultureFit = (scanModules) =>
    scanModules.some((module) => module.id === 'culture-fit');

export const hasWorkPreferences = (scanModules) =>
    scanModules.some((module) => module.id === 'culture-fit-questionnaire');

export const hasInterview = (scanModules) =>
    scanModules.some((module) => module.id === 'interview');

export const hasMentalTest = (scanModules) =>
    scanModules.some((module) => module.id === 'mental-test');

export const isScanNotMentalTest = (scanModules) => {
    return (
        !hasMentalTest(scanModules)
    );
};
