import React, { useState } from 'react';
import { withTranslation } from 'react-i18next';
import {
    ButtonContainer,
    CheckboxWrapper,
    ConditionsContainer,
    Description,
    InfoWrapper,
    InstructionImagesContainer,
    InstructionImagesRow,
    IntroductionImage,
    MobileCameraIntroContainer,
    Title,
    Spacer,
    InfoSignIconWrapper,
} from './MobileCameraIntro.style';
import ShadowButton from 'components/shared/ShadowButton/ShadowButton';
import WellLitIcon from 'assets/images/Well-Lit_Icon_SM_v04.png';
import NoDistractionsIcon from 'assets/images/Distractions_Icon_SM_v04.png';
import NoHandsInFaceIcon from 'assets/images/Hands-Food_Icon_SM_v04.png';
import { InfoSignIcon } from 'evergreen-ui';
import RoundedCheckbox from '../../../RoundedCheckbox/RoundedCheckbox';

const MobileCameraIntro = (props) => {
    const { t, handleClickIntroductionCameraSetup } = props;
    const [cameraTermsAccepted, setCameraTermsAccepted] = useState(false);

    const cameraTermsCheck = (event) => {
        setCameraTermsAccepted(event);
    };

    return (
        <MobileCameraIntroContainer>
            <Title className="main-title">
                {t('CAMERA_SETUP_VIEW.MOBILE.INTRO.TITLE')}
            </Title>
            <InfoWrapper>
                <InfoSignIconWrapper>
                    <InfoSignIcon size={28} color="rgba(248, 204, 50, 1)" />
                </InfoSignIconWrapper>
                <Description>
                    {t('CAMERA_SETUP_VIEW.MOBILE.INTRO.DESCRIPTION')}
                </Description>
            </InfoWrapper>
            <InstructionImagesContainer>
                <InstructionImagesRow>
                    <IntroductionImage
                        alt="No Distractions"
                        src={NoDistractionsIcon}
                    />
                    <div>
                        <Title>
                            {t('CAMERA_SETUP_VIEW.MOBILE.INTRO.INFO_TITLE_1')}
                        </Title>
                        <Description>
                            {t(
                                'CAMERA_SETUP_VIEW.MOBILE.INTRO.INFO_DESCRIPTION_1',
                            )}
                        </Description>
                    </div>
                </InstructionImagesRow>
                <InstructionImagesRow>
                    <IntroductionImage
                        alt="Well-lit and quiet room"
                        src={WellLitIcon}
                    />
                    <div>
                        <Title>
                            {t('CAMERA_SETUP_VIEW.MOBILE.INTRO.INFO_TITLE_2')}
                        </Title>
                        <Description>
                            {t(
                                'CAMERA_SETUP_VIEW.MOBILE.INTRO.INFO_DESCRIPTION_2',
                            )}
                        </Description>
                    </div>
                </InstructionImagesRow>

                <InstructionImagesRow>
                    <IntroductionImage
                        alt="No hands in face or eating and drinking"
                        src={NoHandsInFaceIcon}
                    />
                    <div>
                        <Title>
                            {t('CAMERA_SETUP_VIEW.MOBILE.INTRO.INFO_TITLE_3')}
                        </Title>
                        <Description>
                            {t(
                                'CAMERA_SETUP_VIEW.MOBILE.INTRO.INFO_DESCRIPTION_3',
                            )}
                        </Description>
                    </div>
                </InstructionImagesRow>
            </InstructionImagesContainer>
            <Spacer />
            <ConditionsContainer>
                <CheckboxWrapper>
                    <RoundedCheckbox
                        id="acceptCameraConditions"
                        onChange={cameraTermsCheck}
                    />
                </CheckboxWrapper>
                <Description>
                    {t('CAMERA_SETUP_VIEW.MOBILE.INTRO.CONDITION_DESC')}
                </Description>
            </ConditionsContainer>
            <ButtonContainer>
                <ShadowButton
                    onClick={handleClickIntroductionCameraSetup}
                    disabled={!cameraTermsAccepted}>
                    {t(
                        'CAMERA_SETUP_VIEW.INSTRUCTIONS_FOR_GOOD_ENVIRONMENT_SETUP.CONTINUE_BUTTON',
                    )}
                </ShadowButton>
            </ButtonContainer>
        </MobileCameraIntroContainer>
    );
};

export default withTranslation()(MobileCameraIntro);
