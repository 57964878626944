import React, { useState } from 'react';
import { withTranslation } from 'react-i18next';
import {
    Description,
    MobileCameraSetupContainer,
    Title,
    InfoIconContainer,
    InfoWrapper,
    ButtonContainer,
    Spacer,
    Tab,
    TabContainer,
    PictureWrapper,
    StepBar,
    StepBarContainer,
    Picture,
    IconRow,
    Icon,
} from './MobileCameraSetup.style';
import CameraPreview from '../../../CameraPreview';
import { TickCircleIcon } from 'evergreen-ui';
import ShadowButton from '../../../ShadowButton';
import { ReactComponent as CheckmarkIcon } from 'assets/icons/Picture_Steps_checkmark_icon.svg';
import { ReactComponent as CrossIcon } from 'assets/icons/Picture_Steps_incorrect_cross_icon.svg';
import { ReactComponent as Step1Picture1 } from 'assets/images/Step_1_picture_1.svg';
import { ReactComponent as Step1Picture2 } from 'assets/images/Step_1_picture_2.svg';
import { ReactComponent as Step1Picture3 } from 'assets/images/Step_1_picture_3.svg';
import { ReactComponent as Step2Picture1 } from 'assets/images/Step_2_picture_1.svg';
import { ReactComponent as Step2Picture2 } from 'assets/images/Step_2_picture_2.svg';
import { ReactComponent as Step2Picture3 } from 'assets/images/Step_2_picture_3.svg';
import { ReactComponent as Step3Picture1 } from 'assets/images/Step_3_picture_1.svg';
import { ReactComponent as Step3Picture2 } from 'assets/images/Step_3_picture_2.svg';
import { ReactComponent as Step3Picture3 } from 'assets/images/Step_3_picture_3.svg';
import { ReactComponent as Step4Picture1 } from 'assets/images/Step_4_picture_1.svg';
import { ReactComponent as Step4Picture2 } from 'assets/images/Step_4_picture_2.svg';
import { ReactComponent as Step4Picture3 } from 'assets/images/Step_4_picture_3.svg';
import NoDistractionsIcon from '../../../../../assets/images/Distractions_Icon_SM_v04.png';
import WellLitIcon from '../../../../../assets/images/Well-Lit_Icon_SM_v04.png';
import NoHandsInFaceIcon from '../../../../../assets/images/Hands-Food_Icon_SM_v04.png';

const MobileCameraSetup = (props) => {
    const { t, onComplete, videoStream } = props;
    const [cameraSetupSteps, setCameraSetupSteps] = useState(1);

    const pictureCollection = {
        collection1: [<Step1Picture1 />, <Step1Picture2 />, <Step1Picture3 />],
        collection2: [<Step2Picture1 />, <Step2Picture2 />, <Step2Picture3 />],
        collection3: [<Step3Picture1 />, <Step3Picture2 />, <Step3Picture3 />],
        collection4: [<Step4Picture1 />, <Step4Picture2 />, <Step4Picture3 />],
    };

    const steps = [
        {
            title: t('CAMERA_SETUP_VIEW.MOBILE.STEPS.STEP_1.TITLE'),
            description: t('CAMERA_SETUP_VIEW.MOBILE.STEPS.STEP_1.DESC'),
            icon: NoDistractionsIcon,
            checkList: [
                t('CAMERA_SETUP_VIEW.MOBILE.STEPS.STEP_1.INFO_1'),
                t('CAMERA_SETUP_VIEW.MOBILE.STEPS.STEP_1.INFO_2'),
                t('CAMERA_SETUP_VIEW.MOBILE.STEPS.STEP_1.INFO_3'),
            ],
            images: pictureCollection.collection1,
        },
        {
            title: t('CAMERA_SETUP_VIEW.MOBILE.STEPS.STEP_2.TITLE'),
            description: t('CAMERA_SETUP_VIEW.MOBILE.STEPS.STEP_2.DESC'),
            icon: WellLitIcon,
            checkList: [
                t('CAMERA_SETUP_VIEW.MOBILE.STEPS.STEP_2.INFO_1'),
                t('CAMERA_SETUP_VIEW.MOBILE.STEPS.STEP_2.INFO_2'),
                t('CAMERA_SETUP_VIEW.MOBILE.STEPS.STEP_2.INFO_3'),
            ],
            images: pictureCollection.collection2,
        },
        {
            title: t('CAMERA_SETUP_VIEW.MOBILE.STEPS.STEP_3.TITLE'),
            description: t('CAMERA_SETUP_VIEW.MOBILE.STEPS.STEP_3.DESC'),
            icon: NoHandsInFaceIcon,
            checkList: [
                t('CAMERA_SETUP_VIEW.MOBILE.STEPS.STEP_3.INFO_1'),
                t('CAMERA_SETUP_VIEW.MOBILE.STEPS.STEP_3.INFO_2'),
                t('CAMERA_SETUP_VIEW.MOBILE.STEPS.STEP_3.INFO_3'),
            ],
            images: pictureCollection.collection4,
        },
        {
            description: t('CAMERA_SETUP_VIEW.MOBILE.STEPS.STEP_4.DESC'),
            checkList: [
                t('CAMERA_SETUP_VIEW.MOBILE.STEPS.STEP_4.INFO_1'),
                t('CAMERA_SETUP_VIEW.MOBILE.STEPS.STEP_4.INFO_2'),
                t('CAMERA_SETUP_VIEW.MOBILE.STEPS.STEP_4.INFO_3'),
            ],
        },
    ];

    const renderImage = (pictures, pictureNumber) => (
        <Picture>
            {pictureNumber === 0 ? (
                <CheckmarkIcon
                    style={{
                        transform: 'scale(0.15)',
                        position: 'absolute',
                        bottom: '34%',
                        left: '40%',
                    }}
                />
            ) : (
                <CrossIcon
                    style={{
                        transform: 'scale(0.15)',
                        position: 'absolute',
                        bottom: '34%',
                        left: '40%',
                    }}
                />
            )}
            {pictures[pictureNumber]}
            <TabContainer>
                <Tab className={pictureNumber !== 0 ? 'bad' : ''}>
                    {pictureNumber === 0
                        ? t('CAMERA_SETUP_VIEW.MOBILE.STEPS.GOOD')
                        : t('CAMERA_SETUP_VIEW.MOBILE.STEPS.BAD')}
                </Tab>
            </TabContainer>
        </Picture>
    );

    const handleStep = (step) => {
        setCameraSetupSteps(step);
        window.scrollTo(0, 0);
    };

    const handleContinue = () => {
        setCameraSetupSteps((prevStep) => prevStep + 1);
        window.scrollTo(0, 0);
    };

    const currentStep = steps[cameraSetupSteps - 1];

    return (
        <>
            <MobileCameraSetupContainer
                className={cameraSetupSteps === 4 ? 'final-step' : ''}>
                <Title className="main-title">
                    {t('CAMERA_SETUP_VIEW.MOBILE.STEPS.TITLE')}
                </Title>
                <StepBarContainer>
                    {[1, 2, 3, 4].map((step) => (
                        <StepBar
                            key={step}
                            onClick={() => handleStep(step)}
                            active={cameraSetupSteps >= step}></StepBar>
                    ))}
                </StepBarContainer>
                {cameraSetupSteps === 4 && (
                    <CameraPreview
                        videoStream={videoStream}
                        isPreviewPage={true}
                        isMobileSize={true}
                    />
                )}
                {cameraSetupSteps < 4 && (
                    <>
                        <IconRow>
                            <Icon
                                alt={'icon-' + currentStep}
                                src={currentStep.icon}
                            />
                            <div>
                                <Title>{currentStep.title}</Title>
                                {currentStep.description && (
                                    <Description>
                                        {currentStep.description}
                                    </Description>
                                )}
                            </div>
                        </IconRow>
                        <div className="section">
                            {currentStep.checkList.map((item, index) => (
                                <InfoWrapper key={index}>
                                    <InfoIconContainer>
                                        <TickCircleIcon
                                            size={24}
                                            color={'rgba(65, 89, 112, 1)'}
                                        />
                                    </InfoIconContainer>
                                    <Description>{item}</Description>
                                </InfoWrapper>
                            ))}
                        </div>
                        {currentStep.checkList.map((item, index) => (
                            <PictureWrapper key={index}>
                                {renderImage(currentStep.images, index)}
                            </PictureWrapper>
                        ))}
                        <Spacer />
                        <ButtonContainer>
                            <ShadowButton onClick={handleContinue}>
                                {t(
                                    'CAMERA_SETUP_VIEW.SET_UP_CAMERA.CONTINUE_BUTTON',
                                )}
                            </ShadowButton>
                        </ButtonContainer>
                    </>
                )}
                {cameraSetupSteps === 4 && (
                    <>
                        <Description className="last-step">
                            {currentStep.description}
                        </Description>
                        {currentStep.checkList.map((item, index) => (
                            <InfoWrapper key={index}>
                                <InfoIconContainer>
                                    <TickCircleIcon
                                        size={24}
                                        color={'rgba(65, 89, 112, 1)'}
                                    />
                                </InfoIconContainer>
                                <Description>{item}</Description>
                            </InfoWrapper>
                        ))}
                        <Spacer />
                        <ButtonContainer>
                            <ShadowButton onClick={onComplete}>
                                {t(
                                    'CAMERA_SETUP_VIEW.MOBILE.STEPS.START_BUTTON',
                                )}
                            </ShadowButton>
                        </ButtonContainer>
                    </>
                )}
            </MobileCameraSetupContainer>
        </>
    );
};

export default withTranslation()(MobileCameraSetup);
