import styled from 'styled-components';
import {breakpoints} from "../../App.style";

export const Container = styled.div`
    display: flex;
    flex-direction: column;
    min-height: 100vh;

    justify-content: space-between;
    align-items: center;
    background: ${(props) => props.theme.backgroundColor};
    @media only screen and (max-height: 542px) {
        height: fit-content;
    }
    @media only screen and (min-width: ${breakpoints.tablet}) {
        padding: 0 2vw;
    }
    @media only screen and (min-width: ${breakpoints.desktop}) {
        padding: 0 4vw;
    }
    @media only screen and (max-width: ${breakpoints.mobile}) {
        justify-content: flex-start;
    }
    .spacer {
        flex-grow: 1;
    }
`;

export const TextContainer = styled.div`
    width: 80%;
    max-width: 680px;
    margin-bottom: 80px;
    @media only screen and (max-width: ${breakpoints.tablet}) {
        padding: 0 2vw;
        margin-bottom: 0;
    }
`;

export const Message = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding-bottom: 75px;

    h1 {
        color: ${(props) => props.theme.textColor};
        margin-bottom: 15px;
        text-align: left;
        @media only screen and (max-width: ${breakpoints.tablet}) {
            font-size: 1.5em;
        }
    }

    h3 {
        color: ${(props) => props.theme.textColor};
        font-weight: normal;
        margin-bottom: 15px;
        text-align: left;
        @media only screen and (max-width: ${breakpoints.tablet}) {
            font-size: 1em;
        }
        &:last-child {
            margin-top: 15px;
        }
    }
`;

export const Title = styled.h1`
    color: ${(props) => props.theme.textColor};
    margin: 10px 0;
`;

export const Subtitle = styled.h3`
    color: ${(props) => props.theme.textColor};
    font-weight: normal;
    margin: 0;
`;

export const Header = styled.header`
    display: flex;
    justify-content: center;
    width: 100%;
    padding-top: 1.6em;
    @media only screen and (max-width: ${breakpoints.tablet}) {
        padding-bottom: 2em;
    }
`;

export const Footer = styled.footer`
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 30px;

    img {
        width: 200px;
        @media only screen and (max-width: ${breakpoints.tablet}) {
            width: 180px;
        }
    }
`;
