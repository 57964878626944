import styled from 'styled-components';

export const Controls = styled.div`
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
    padding: 15px;
    gap: 16px;
`;

export const ButtonsContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    max-width: 150px;
    cursor: pointer;

    span {
        color: ${(props) => props.theme.secondaryTextColor};
    }
`;

export const RecordingLabel = styled.div`
    position: absolute;
    bottom: 0;
    left: 0;
    background: #3b3b3b;
    color: #fff;
    border-radius: 25px;
    display: flex;
    flex-flow: row nowrap;
    padding: 5px 15px;
    margin: 10px;
    font-weight: bold;
    align-items: center;

    &::before {
        display: block;
        content: '';
        position: relative;
        background: red;
        border-radius: 12px;
        width: 12px;
        height: 12px;
        margin-right: 10px;
    }
`;

export const CameraContainer = styled.div`
    position: relative;
    display: flex;
    overflow: hidden;
    border-radius: 10px;
    padding: 0;
    margin: 0;
    height: 100%;
    align-items: center;
    justify-content: center;
`;

export const VideoCenter = styled.div`
    width: 100%;
    display: flex;
    justify-content: center;
`;

export const VideoContainer = styled.div`
    position: relative;
    display: flex;
    overflow: hidden;
    border-radius: 10px;
    padding: 0;
    margin: 0;
    height: 100%;
    align-items: center;
    justify-content: center;

    video {
        width: 100%;
        height: 100%;
        background: black;
    }
`;

export const TopButtonContainer = styled.div`
    width: 100%;
    display: flex;
    justify-content: center;
`;
