import { isDesktop } from './device-data';

export function isIOSVersionAllowed() {
    let check = !isDesktop();
    (function (a) {
        if (/iP(hone|od|ad)/.test(a)) {
            const iOSMatch = a.match(/OS (\d+)_?(\d+)?/);
            if (iOSMatch) {
                const iOSMajorVersion = parseInt(iOSMatch[1], 10);
                const iOSMinorVersion = iOSMatch[2]
                    ? parseInt(iOSMatch[2], 10)
                    : 0;
                const iOSVersion = iOSMajorVersion + iOSMinorVersion / 10;
                check = iOSVersion >= 14.3;
            }
        } else check = true;
    })(navigator.userAgent || navigator.vendor || window.opera);

    return check;
}
