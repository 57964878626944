import React, { useEffect } from 'react';
import { withTranslation } from 'react-i18next';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { Link } from 'react-router-dom';

import {
    CompanyName,
    Container,
    FadeIn,
    Footer,
    Header,
    ScanButton,
    ScanCardTitleWrapper,
    ScanListContainer,
    ScanName,
    ScanSubTitle,
    ScanSubTitleValue,
    Title,
} from './UserScans.style';
import { fetchConfig } from 'store/config/config.actions';
import poweredByNeurolyticsLogo from 'assets/images/powered-by-neurolytics.png';

import Skeleton from '@material-ui/lab/Skeleton';
import withMediaQuery from '../../hoc/MediaQuery/withMediaQuery';
import CompanyLogo from '../shared/CompanyLogo/CompanyLogo';
import { checkAllowedModulesAndDevices } from '../../utils/checkAllowedModulesAndDevices';

const UserScans = (props) => {
    const { t, onLogout, isTabletAndMobileSize } = props;
    const { user } = useAppSelector((state) => state.auth);
    const { companies, scans, loading } = useAppSelector(
        (state) => state.config,
    );
    const dispatch = useAppDispatch();
    const logoUrl =
        'https://s3.eu-west-1.amazonaws.com/neurolytics.public/neurolytics/images/neurolytics-logo.png';

    useEffect(() => {
        if (user && user.scans) {
            for (const scan of user.scans) {
                dispatch(
                    fetchConfig(
                        scan.assessment_id,
                        scan.target_group_id,
                        scan.invitation_id,
                    ),
                );
            }
        }
    }, [dispatch, user]);

    const renderScanList = () => {
        // todo: if there is an error, an error message should be shown to the user
        if (loading) {
            return (
                <Skeleton
                    className="skeleton"
                    variant="rect"
                    width={220}
                    height={60}
                    role="status"
                />
            );
        }

        if (!loading && user.scans.length === 0) {
            return <FadeIn>{t('SCAN_LIST.NO_SCAN')}</FadeIn>;
        }

        if (
            !loading &&
            scans &&
            Object.keys(scans).length === 0 &&
            user.scans.length > 0
        ) {
            // This is usually the result of an error experienced by developers only:
            // when a 404 error is returned from the api trying to fetch config for the wrong environment (local/development)
            return (
                <Skeleton
                    className="skeleton"
                    variant="rect"
                    width={220}
                    height={60}
                />
            );
        }

        if (!loading && scans && Object.keys(scans).length >= 1) {
            return (
                <FadeIn role="navigation" aria-label="your scans">
                    {Object.entries(scans).map(([invitationId, scan]) => {
                        const isCompleted = scan.isCompleted;
                        const modules = scan.modules
                            .filter((module) => {
                                return (
                                    module.id === 'culture-fit-questionnaire' ||
                                    module.type === 'interview-module' ||
                                    (module.type ===
                                        'assessment-questionnaire' &&
                                        module.id.includes('mental'))
                                );
                            })
                            .map((module) => {
                                if (module.id === 'culture-fit-questionnaire') {
                                    return 'Culture Fit';
                                } else if (module.type === 'interview-module') {
                                    return 'Speaking';
                                } else if (
                                    module.type ===
                                        'assessment-questionnaire' &&
                                    module.id.includes('mental')
                                ) {
                                    return 'Cognitive Abilities';
                                }
                                return '';
                            });
                        const isModulesAndDevicesAllowed =
                            checkAllowedModulesAndDevices(scan.modules);

                        return (
                            <ScanButton
                                key={invitationId}
                                className={
                                    isCompleted || !isModulesAndDevicesAllowed
                                        ? 'disabled'
                                        : 'enabled'
                                }>
                                {!isCompleted && isModulesAndDevicesAllowed && (
                                    <Link
                                        to={`/${scan.companySlug}/${scan.assessmentId}/${invitationId}`}>
                                        <div>
                                            <CompanyName>
                                                {
                                                    companies[scan.companySlug]
                                                        .companyName
                                                }
                                            </CompanyName>
                                        </div>
                                        <div>
                                            <ScanName>
                                                {scan.targetGroupName}
                                            </ScanName>
                                        </div>
                                        <div>
                                            <ScanSubTitle>
                                                Modules:
                                            </ScanSubTitle>{' '}
                                            <ScanSubTitleValue>
                                                {modules.join(', ')}
                                            </ScanSubTitleValue>
                                        </div>
                                    </Link>
                                )}
                                {isCompleted && (
                                    <>
                                        <ScanCardTitleWrapper>
                                            <CompanyName>
                                                {
                                                    companies[scan.companySlug]
                                                        .companyName
                                                }
                                            </CompanyName>
                                            <ScanSubTitle>
                                                Completed
                                            </ScanSubTitle>
                                        </ScanCardTitleWrapper>
                                        <div>
                                            <ScanName>
                                                {scan.targetGroupName}
                                            </ScanName>
                                        </div>
                                        <div>
                                            <ScanSubTitle>
                                                Modules:
                                            </ScanSubTitle>{' '}
                                            <ScanSubTitleValue>
                                                {modules.join(', ')}
                                            </ScanSubTitleValue>
                                        </div>
                                    </>
                                )}
                                {!isCompleted && !isModulesAndDevicesAllowed && (
                                    <>
                                        <ScanCardTitleWrapper>
                                            <CompanyName>
                                                {
                                                    companies[scan.companySlug]
                                                        .companyName
                                                }
                                            </CompanyName>
                                            <ScanSubTitle>
                                                Available on desktop
                                            </ScanSubTitle>
                                        </ScanCardTitleWrapper>
                                        <div>
                                            <ScanName>
                                                {scan.targetGroupName}
                                            </ScanName>
                                        </div>
                                        <div>
                                            <ScanSubTitle>
                                                Modules:
                                            </ScanSubTitle>{' '}
                                            <ScanSubTitleValue>
                                                {modules.join(', ')}
                                            </ScanSubTitleValue>
                                        </div>
                                    </>
                                )}
                            </ScanButton>
                        );
                    })}
                </FadeIn>
            );
        }
    };

    return (
        <Container>
            <Header className="top-header">
                <CompanyLogo logoUrl={logoUrl} />
            </Header>
            <Header>
                {isTabletAndMobileSize && (
                    <div className="user-name-title">{user.name}</div>
                )}
                <Title>
                    <FadeIn>{t('SCAN_LIST.TITLE')}</FadeIn>
                </Title>
                <ScanListContainer>{renderScanList()}</ScanListContainer>
            </Header>

            <Footer>
                {isTabletAndMobileSize && (
                    <div className="log-out" onClick={onLogout}>
                        Logout
                    </div>
                )}
                {!isTabletAndMobileSize && (
                    <>
                        <div className="user-name">{user.name}</div>
                        <div className="log-out" onClick={onLogout}>
                            Logout
                        </div>
                    </>
                )}
                <img src={poweredByNeurolyticsLogo} alt="Neurolytics Logo" />
            </Footer>
        </Container>
    );
};

export default withMediaQuery(withTranslation()(UserScans));
