import React, { Component } from 'react';

import { PreviewContainer, ButtonContainer } from './CameraPreview.style';

class CameraPreview extends Component {
    constructor(props) {
        super(props);
        this.videoRef = React.createRef();
    }
    componentDidMount() {
        this.updateVideoSource(this.props.videoStream);
    }
    componentDidUpdate(prevProps, prevState) {
        const videoStream = this.props.videoStream;
        if (prevProps !== this.props && prevProps.videoStream !== videoStream) {
            this.updateVideoSource(videoStream);
        }
    }

    updateVideoSource(videoStream) {
        if (this.videoRef && this.videoRef.current) {
            this.videoRef.current.srcObject = videoStream;
            this.videoRef.current.style.opacity = 1;
        }
    }

    render() {
        return (
            <>
                <PreviewContainer>
                    <video
                        aria-label="camera preview"
                        style={{ opacity: 0 }}
                        ref={this.videoRef}
                        className="Camera__Video"
                        autoPlay
                        muted
                        playsInline
                    />
                    {this.props.children}
                    {this.props.isPreviewPage && !this.props.isMobileSize && (
                        <ButtonContainer onClick={this.props.cameraHelp}>
                            <svg
                                width="24"
                                height="23"
                                viewBox="0 0 16 15"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg">
                                <path
                                    d="M14.9856 10.4571L11.5356 8.48572V6.51435L14.9856 4.54297V10.4571Z"
                                    stroke="white"
                                    strokeWidth="1.47853"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                />
                                <path
                                    d="M2.35107 1.09302L14.0001 13.907"
                                    stroke="white"
                                    strokeWidth="1.47853"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                />
                                <path
                                    d="M7.69745 3.31079H9.56439C10.0872 3.31079 10.5887 3.51849 10.9584 3.88819C11.3281 4.2579 11.5358 4.75933 11.5358 5.28217V11.1963C11.5358 11.327 11.4838 11.4524 11.3914 11.5448C11.299 11.6372 11.1736 11.6891 11.0429 11.6891H3.15741C2.63457 11.6891 2.13314 11.4814 1.76344 11.1117C1.39373 10.742 1.18604 10.2406 1.18604 9.71777V3.80364C1.18604 3.67292 1.23796 3.54757 1.33039 3.45514C1.42281 3.36272 1.54817 3.31079 1.67888 3.31079H4.36712"
                                    stroke="white"
                                    strokeWidth="1.47853"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                />
                            </svg>
                            <span>{this.props.text}</span>
                        </ButtonContainer>
                    )}
                </PreviewContainer>
            </>
        );
    }
}

export default CameraPreview;
