import styled from 'styled-components';
import {breakpoints} from "../../App.style";

export const Layout = styled.div`
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 0 20px;
    @media only screen and (max-height: 600px){
        height: fit-content;
        padding: 2em;
    }
    @media only screen and (min-width: ${breakpoints.tablet}) {
        padding: 0 2vw;
    }
    @media only screen and (min-width: ${breakpoints.desktop}) {
        padding: 0 4vw;
    }
    @media only screen and (max-width: ${breakpoints.tablet}) {
        justify-content: flex-start;
        gap: 3em;
        padding: 2em;
        height: auto;
        min-height: unset;
    }
    @media only screen and (max-width: ${breakpoints.mobile}) {
        justify-content: space-between;
        min-height: unset;
        height: calc(100vh - 4em);
        @media only screen and (max-height: 650px) {
            height: auto;
        }
    }
    .spacer {
        flex-grow: 1;
    }
`;
export const Wrapper = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
`;

export const TextContainer = styled.div`
    max-width: 1000px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    h1,
    h2,
    p {
        margin: 0;
        padding-bottom: 25px;
        color: ${(props) => props.theme.textColor};
        text-align: left;
    }
    h1 {
        @media only screen and (max-width: ${breakpoints.mobile}) {
            font-size: 1.7em;
        }
    }
    p {
        @media only screen and (max-width: ${breakpoints.mobile}) {
            font-size: 1em;
        }
    }
    h2 {
        font-size: 1em;
        color: ${(props) => props.theme.textColor};
        @media only screen and (max-width: ${breakpoints.mobile}) {
            font-size: 1em;
        }
    }
`;

export const ButtonContainer = styled.div`
    margin-top: 25px;
    min-width: 300px;
    @media only screen and (max-width: ${breakpoints.mobile}) {
        margin-top: 0;
        min-width: unset;
        width: 100%;
    }
`;
