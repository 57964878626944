import styled from 'styled-components';

export const MobileCameraIntroContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    padding: 2em 1.3em;
    height: calc(100vh - 4em);
    @media only screen and (max-height: 800px) {
        height: auto;
    }
`;

export const Title = styled.div`
    font-size: 1em;
    color: ${(props) => props.theme.textColor};
    font-weight: 700;
    line-height: 28px;
    text-align: left;
    &.main-title {
        font-size: 2em;
    }
`;

export const Description = styled.div`
    font-size: 1em;
    color: rgba(122, 139, 155, 1);
    font-weight: 400;
    line-height: 25px;
    text-align: left;
`;

export const InfoWrapper = styled.div`
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;
    padding: 1.5em 0 1em 0;
`;

export const InfoSignIconWrapper = styled.div`
    padding-right: 8px;
`;

export const InstructionImagesContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
`;

export const InstructionImagesRow = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 1em 0;
`;

export const IntroductionImage = styled.img`
    width: 100%;
    max-width: 100px;
    height: auto;
    padding-right: 12px;
`;

export const ConditionsContainer = styled.div`
    display: flex;
    flex-flow: row nowrap;
    align-items: flex-start;
    margin: 1.5em 0;
    background: #f4f4f4;
    padding: 1em;
`;

export const CheckboxWrapper = styled.div`
    padding-top: 4px;
    padding-right: 8px;
`;

export const ButtonContainer = styled.div`
    width: 90%;
    display: flex;
    justify-content: center;
    align-self: center;
`;

export const Spacer = styled.div`
    flex-grow: 1;
`;
