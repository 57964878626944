import styled from 'styled-components';
import { breakpoints } from '../../../App.style';

export const Question = styled.h1`
    color: ${(props) => props.theme.textColor};
    font-weight: bold;
    margin-top: 5px;
    font-size: 1.6em;
    padding-bottom: 1em;
    @media only screen and (max-width: ${breakpoints.tablet}) {
        font-size: 1.3em;
    }
`;

export const RecorderDescription = styled.span`
    color: ${(props) => props.theme.textColor};
    font-size: 1.3em;
    @media only screen and (max-width: ${breakpoints.tablet}) {
        font-size: 1em;
    }
`;

export const WatchVideoAgainContainer = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    gap: 8px;
    padding-bottom: 8px;
    &svg  {
        cursor: pointer;
        width: 20px;
        height: 16px;
    }
    &:hover{
        opacity: 0.7;
    }
`;

export const WatchVideoAgainMessage = styled.span`
    color: ${(props) => props.theme.mainColor};
    cursor: pointer;
    font-size: 1.1em;
    @media only screen and (max-width: ${breakpoints.tablet}) {
        font-size: 0.9em;
    }
`;

export const RecorderContainer = styled.div`
    margin: 20px 0 0 0;
`;

export const RecorderFooter = styled.div`
    display: flex;
    flex-flow: row nowrap;
    justify-content: flex-end;
    width: 100%;
    margin-top: 10px;
`;

export const ButtonContainer = styled.div`
    width: 300px;
    @media only screen and (max-width: ${breakpoints.mobile}) {
        width: 100%;
    }
`;

export const VideoLoadingPlaceholder = styled.div`
    position: absolute;
    top: 0;
    left: 0;
    display: flex;
    width: 100%;
    height: 100%;
    justify-content: center;
    align-items: center;
    background-color: rgba(67, 89, 112, 0.8);
    opacity: 1;
    transition: 0.3s all ease-in-out;
`;
