import styled from 'styled-components';
import { breakpoints } from '../../App.style';

export const ContainerWrapper = styled.div`
    position: relative;
    min-height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0 5vw;

    @media only screen and (max-width: ${breakpoints.tablet}) {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: center;
        margin: 0;
    }
`;
export const Container = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    min-height: 100vh;
    @media only screen and (max-width: ${breakpoints.tablet}) {
        justify-content: flex-start;
        margin: 0;
        overflow-y: auto;
    }
    .power-logo {
        width: 200px;
        padding-bottom: 16px;
    }
    .spacer {
        flex-grow: 1;
    }
`;

export const Header = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    padding-top: 1.6em;
`;

export const TwoColumnsLayout = styled.div`
    display: flex;
    flex-flow: row nowrap;
    margin-bottom: 100px;
    @media only screen and (max-width: ${breakpoints.tablet}) {
        flex-flow: column;
        justify-content: space-between;
        margin-bottom: 0;
    }
`;

export const VideoColumn = styled.div`
    display: flex;
    position: relative;
    width: 45vw;
    min-width: 400px;
    max-width: 900px;
    height: auto;
    justify-content: center;
    align-items: center;
    @media only screen and (max-width: ${breakpoints.tablet}) {
        width: 100%;
        min-width: 200px;
        padding-top: 2em;
        max-height: 400px;
    }
`;

export const Video = styled.video`
    width: 100%;
    height: auto;
    @media only screen and (max-width: ${breakpoints.tablet}) {
        height: 100%;
    }
`;

export const ContentColumn = styled.div`
    position: relative;
    flex: 1;
    min-width: 350px;
    display: flex;
    flex-direction: column;
    margin-left: 5vw;
    color: ${(props) => props.theme.textColor};
    @media only screen and (max-width: ${breakpoints.tablet}) {
        min-width: 300px;
        margin-left: 0;
    }
`;

export const Text = styled.div`
    display: flex;
    flex-direction: column;
    color: ${(props) => props.theme.textColor};
    @media only screen and (max-width: ${breakpoints.tablet}) {
        align-items: flex-start;
        justify-content: center;
        text-align: left;
        padding: 0 1.5em;
    }
    h1,
    p {
        margin: 0;
        padding: 0;
    }

    p {
        margin-top: 0.938em;
        @media only screen and (max-width: ${breakpoints.tablet}) {
            font-size: 1em;
            font-weight: normal;
        }
    }

    h1 {
        @media only screen and (max-width: ${breakpoints.tablet}) {
            font-size: 1.5em;
            padding-top: 1em;
        }
    }
    h2 {
        font-size: 1em;
        color: ${(props) => props.theme.textColor};
        margin-top: 15px;
        @media only screen and (max-width: ${breakpoints.tablet}) {
            font-size: 1em;
            font-weight: normal;
        }
    }
`;

export const HighlightedBlock = styled.div`
    display: flex;
    flex-flow: row nowrap;
    padding: 15px;
    margin-bottom: 15px;
    background: #f4f4f4;
    align-items: center;

    p {
        font-size: 0.9em;
        margin: 0 0 0 15px;
    }
`;
export const ConditionsContainer = styled.div`
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    margin: 30px 0;
    background: #f4f4f4;
    padding: 20px;
    @media only screen and (max-width: ${breakpoints.tablet}) {
        margin: 2em 0;
    }
    @media only screen and (max-width: 375px) {
        padding: 0 2em;
    }
`;

export const ConditionsParagraph = styled.span`
    padding-left: 15px;
    font-weight: normal;
    color: #60656a;
    @media only screen and (max-width: ${breakpoints.tablet}) {
        font-weight: 400;
        font-size: 1em;
        padding-left: 0.8em;
    }
    a {
        color: ${(props) => props.theme.mainColor};
        font-weight: bold;

        &:hover {
            color: ${(props) => props.theme.darkerMainColor};
        }
    }
`;

export const ContentFooter = styled.div`
    display: flex;
    justify-content: flex-end;
    width: 100%;
    @media only screen and (max-width: ${breakpoints.tablet}) {
        flex-direction: column;
        align-items: center;
        padding-bottom: 1em;
        gap: 1em;
    }
    @media only screen and (max-width: ${breakpoints.mobile}) {
        gap: 2em;
    }
    img {
        width: 220px;
        @media only screen and (max-width: ${breakpoints.tablet}) {
            width: 180px;
        }
    }
`;

export const ButtonContainer = styled.div`
    width: 200px;
    margin-top: 20px;
    @media only screen and (max-width: ${breakpoints.tablet}) {
        margin-top: 0;
        width: 80%;
    }
`;
