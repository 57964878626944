import {
    isScanNotMentalTest,
    isScanOnlyWorkPreferences,
} from './ScanModulesDetection';
import { isIOSVersionAllowed } from './detect-mobile';
import { isDesktop } from './device-data';

export const checkAllowedModulesAndDevices = (scanModules) => {
    const isQuestionnaireOnlyScan = isScanOnlyWorkPreferences(scanModules);
    const isNotMentalTestScan = isScanNotMentalTest(scanModules);
    const isDesktopDevice = isDesktop();
    const isMobileIOSVersionAllowed = isIOSVersionAllowed();
    if (
        isDesktopDevice ||
        isQuestionnaireOnlyScan ||
        (isNotMentalTestScan && isMobileIOSVersionAllowed)
    )
        return true;
    if (
        !isDesktopDevice &&
        !isQuestionnaireOnlyScan &&
        ((isNotMentalTestScan && !isMobileIOSVersionAllowed) ||
            !isNotMentalTestScan)
    )
        return false;
};
