import styled from 'styled-components';
import { createGlobalStyle } from 'styled-components';

import RobotoBold from 'assets/fonts/Roboto-Bold.ttf';
import RobotoRegular from 'assets/fonts/Roboto-Regular.ttf';

const stylingReset = `
    body,
    h1,
    h2,
    h3,
    h4,
    p,
    ul,
    ol,
    li {
    margin: 0;
    }
`;

export const breakpoints = {
    bigDesktop: '1600px',
    desktop: '1440px',
    smallDesktop: '1024px',
    tablet: '768px',
    mobile: '480px',
    smallMobile: '320px',
};

export const AppContainer = styled.div`
    margin: auto;
    width: 100%;
    min-height: 100vh;
    background: ${(props) => props.theme.backgroundColor};
    font-family: Roboto, serif;
    @media only screen and (min-width: ${breakpoints.smallDesktop}) {
        max-width: 80vw;
    }
    .cookie-theme {
        background: rgba(64, 89, 111, 0.85);
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
    }
`;

export const GlobalStyles = createGlobalStyle`
    ${stylingReset}

    @font-face {
        font-family: Roboto;
      src: url('${RobotoRegular}') format('opentype');
    }

    @font-face {
        font-family: Roboto;
        src: url('${RobotoBold}') format('opentype');
        font-weight: bold;
    }
    html, body {
        font-family: Roboto, serif;
        overscroll-behavior-y: none;
    }
    
    html {
        scrollbar-width: thin; /* "auto" or "thin" */
        scrollbar-color: rgba(64, 89, 111, 0.8) transparent; /* Thumb and track color */
    }

    ::-webkit-scrollbar {
        width: 10px;
        height: 10px;
    }

    ::-webkit-scrollbar-track {
        background-color: transparent;
        border-radius: 12px;
    }

    ::-webkit-scrollbar-thumb {
        background: rgba(64, 89, 111, 0.8);
        border-radius: 12px;
        border: 3px solid rgba(64, 89, 111, 1);
        opacity: 0.5;
    }
    
    #root {
      width: 100%;
      height: 100vh;
      font-family: Roboto, serif;
    }

    body {
        margin: 0;
        padding: 0;
        font-size: 16px;
        font-family: Roboto, serif;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        @media only screen and (min-width: ${breakpoints.bigDesktop})
        {
            background: #F5F5F5;
        }
        .rhc-settings {
            height: 100%;
            .rhc-settings__content {
                background: white;
                color: #435970;
                border: none;
                border-radius: 8px;
                box-shadow: 0 4px 32px 0 rgba(65, 89, 112, 0.6);
                height: calc(100% - 12rem);
                @media only screen and (min-width: ${breakpoints.bigDesktop}) {
                    height: auto;
                }
                @media only screen and (max-width: ${breakpoints.smallMobile}) {
                    height: 85%;
                }
                @media only screen and (max-height: 600px) {
                    height: calc(100% - 1rem);
                    overflow-y: auto;
                }

                .rhc-settings__content__main {
                    scrollbar-color: rgba(64, 89, 111, 0.8) transparent;
                }

                .rhc-settings__content__header {
                    padding-bottom: 0;
                }

                .react-toggle--checked .react-toggle-track {
                    background-color: #00a5ac;
                }

                .rhc-settings__content__main__item__title {
                    align-items: center;
                }

                .react-toggle-track {
                    background-color: #435970;
                }

                .react-toggle--checked:hover:not(.react-toggle--disabled) .react-toggle-track {
                    background-color: #00a5ac;
                }

                .rhc-settings__content__footer {
                    display: flex;
                    flex-direction: row;
                    justify-content: space-between;
                    @media only screen and (max-width: ${breakpoints.mobile}) {
                        flex-direction: column;
                    }
                }

                .rhc-settings__content__footer__primary {
                    background-color: #00a5ac;
                    border-color: #00a5ac;
                    color: white;
                    width: 32%;
                    font-size: 1.1em;
                    @media only screen and (max-width: ${breakpoints.tablet}) {
                        font-size: 1em;
                    }
                    @media only screen and (max-width: ${breakpoints.mobile}) {
                        font-size: 1em;
                        width: 60%;
                    }
                    @media only screen and (max-width: ${breakpoints.smallMobile}) {
                        width: 70%;
                    }

                    &:hover {
                        opacity: 0.7;
                    }
                }

                .rhc-settings__content__footer__secondary {
                    background-color: white;
                    border-color: rgba(26, 166, 173, 1);
                    color: rgba(26, 166, 173, 1);
                    width: 32%;
                    font-size: 1.1em;
                    @media only screen and (max-width: ${breakpoints.tablet}) {
                        font-size: 1em;
                    }
                    @media only screen and (max-width: ${breakpoints.mobile}) {
                        font-size: 1em;
                        width: 60%;
                    }
                    @media only screen and (max-width: ${breakpoints.smallMobile}) {
                        width: 70%;
                    }

                    &:hover {
                        opacity: 0.7;
                    }
                }
            }
        }
        .rhc-banner {
            background: white;
            border: none;
            border-radius: 8px;
            width: fit-content;
            top: 0;
            height: fit-content;
            margin: auto;
            display: flex;
            justify-content: center;
            align-items: center;
            box-shadow: 0 4px 32px 0 rgba(65, 89, 112, 0.6);
            @media only screen and (max-height: 430px) {
                height: 50%;
            }
            @media only screen and (max-width: ${breakpoints.mobile}) {
                min-height: 57%;
                overflow-y: auto;
            }
            .rhc-banner__content {
                width: 80%;
                max-width: 40vw;
                min-height: fit-content;
                max-height: 80vh;
                display: grid;
                grid-template-columns: repeat(2, 1fr);
                grid-template-rows: 1fr auto;
                height: 100%;
                gap: 20px;
                align-content: space-between;
                @media only screen and (max-height: 430px) {
                    overflow-y: auto;
                }
                @media only screen and (max-width: ${breakpoints.smallDesktop}) {
                    max-width: 50vw;
                }
                @media only screen and (max-width: ${breakpoints.tablet}) {
                    max-width: 60vw;
                }
                @media only screen and (max-width: ${breakpoints.mobile}) {
                    width: 90%;
                    padding: 0 1.5em;
                    display: flex;
                    flex-direction: column;
                }
                @media only screen and (max-width: ${breakpoints.mobile}) {
                    max-width: 70vw;
                }
                @media only screen and (max-width: ${breakpoints.smallMobile}) {
                    max-width: 75vw;
                }
                .rhc-banner__content__message {
                    color: #435970;
                    font-size: 1.1em;
                    text-align: left;
                    line-height: 28px;
                    grid-column: 1 / -1;
                    @media only screen and (max-width: ${breakpoints.mobile}) {
                        font-size: 1em;
                    }
                    @media only screen and (max-width: ${breakpoints.smallMobile}) {
                        overflow-y: auto;
                        max-height: 40vh;
                    }
                    a {
                        color: #00a5ae;
                        font-size: 1em;
                        @media only screen and (max-width: ${breakpoints.mobile}) {
                            font-size: 0.9em;
                        }
                        &:hover {
                            opacity: 0.7;
                        }
                    }
                    .cookie-title {
                        font-weight: bold;
                        font-size: 1.2em;
                    }
                }
                .rhc-banner__content__primary {
                    background-color: #00a5ac;
                    border: none;
                    color: white;
                    width: 100%;
                    font-size: 1.1em;
                    min-height: 36px;
                    min-width: 100px;
                    @media only screen and (max-width: ${breakpoints.smallDesktop}) {
                        font-size: 1em;
                    }
                    @media only screen and (max-width: ${breakpoints.mobile}) {
                        width: inherit;
                    }
                    &:hover {
                        opacity: 0.7;
                    }
                    .cookie-button{
                        display: flex;
                        flex-direction: row;
                        align-items: center;
                        justify-content: center;
                        width: 100%;
                        height: 100%;
                    }
                }
                .rhc-banner__content__secondary {
                    background-color: white;
                    border-color: rgba(26, 166, 173, 1);
                    color: rgba(26, 166, 173, 1);
                    width: inherit;
                    font-size: 1.1em;
                    min-height: 36px;
                    min-width: 100px;
                    @media only screen and (max-width: ${breakpoints.smallDesktop}) {
                        font-size: 1em;
                        width: 100%;
                    }
                    @media only screen and (max-width: ${breakpoints.mobile}) {
                        width: inherit;
                    }
                    &:hover {
                        opacity: 0.7;
                    }
                }
            }
        }
    }

    h1 {
        font-weight: bold;
        font-size: 1.5em;
        line-height: 1.5em;
    }

    h2 {
        font-weight: bold;
        line-height: 1.5em;
    }

    input {
        font-size: 1em;
        line-height: 1.5em;
    }

    h3, span, p {
        font-size: 1em;
        line-height: 1.5em;
    }

    a {
        font-size: 1em; line-height: 1.5em;
        text-decoration: none;
    }
`;
