import styled from 'styled-components';
import { breakpoints } from '../../../../../App.style';

export const MobileCameraSetupContainer = styled.div`
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    padding: 2em 1.3em;
    &.final-step {
        height: calc(100vh - 4em);
        @media only screen and (max-height: 750px) {
            height: auto;
        }
    }
    .section {
        padding-top: 1.3em;
    }
`;

export const Title = styled.div`
    font-size: 1.3em;
    color: ${(props) => props.theme.textColor};
    font-weight: 700;
    line-height: 30px;
    text-align: left;
    &.main-title {
        font-size: 2em;
    }
`;

export const Description = styled.div`
    font-size: 1em;
    color: rgba(84, 106, 126, 1);
    font-weight: 400;
    line-height: 23px;
    text-align: left;
    &.last-step {
        padding: 1.3em 0;
    }
`;

export const InfoWrapper = styled.div`
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;
    padding-bottom: 0.6em;
`;

export const InfoIconContainer = styled.div`
    padding-right: 0.4em;
`;

export const Spacer = styled.div`
    flex-grow: 1;
`;

export const IconRow = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
`;

export const Icon = styled.img`
    width: 100%;
    max-width: 100px;
    height: auto;
    padding-right: 12px;
`;

export const ButtonContainer = styled.div`
    width: 90%;
    display: flex;
    justify-content: center;
    align-self: center;
    padding-top: 0.6em;
`;

export const TabContainer = styled.div`
    position: absolute;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    bottom: 3%;
    width: 100%;
`;

export const Tab = styled.button`
    color: white;
    background: rgba(0, 165, 172, 1);
    border: 1px solid rgba(0, 165, 172, 1);
    font-size: 0.9em;
    font-weight: 400;
    line-height: 22px;
    text-align: center;
    padding: 6px 12px;
    width: 30%;
    border-radius: 8px;
    &.bad {
        background: red;
        border: 1px solid red;
    }
    @media only screen and (max-width: ${breakpoints.smallMobile}) {
        width: 40%;
    }
`;

export const PictureWrapper = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 80%;
    margin: auto;
    padding: 0.8em 0;
`;

export const Picture = styled.div`
    width: 100%;
    position: relative;
    svg {
        width: 100%;
        height: 100%;
    }
`;

export const StepBarContainer = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    width: 100%;
    padding: 24px 0;
`;

export const StepBar = styled.div`
    border-top: ${(props) =>
        props.active
            ? '6px solid rgba(0, 165, 172, 1)'
            : '6px solid rgba(228, 234, 237, 1)'};
    border-radius: 2px;
    color: ${(props) =>
        props.active ? 'rgba(0, 165, 172, 1)' : 'rgba(141, 155, 169, 1)'};
    padding-top: 4px;
    margin-right: 0.5em;
    width: 50%;
`;
